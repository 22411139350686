'use client';

import { useCallback, useMemo } from 'react';

let tldJsPromise = null;

const loadTld = () => {
  if (tldJsPromise === null) {
    tldJsPromise = import('tldjs').then(({ default: tldjs }) => tldjs);
  }

  return tldJsPromise;
};

const useTld = () => {
  const getDomain = useCallback(async (key) => {
    const lib = await loadTld();
    return lib.getDomain(key);
  }, []);

  const tldjs = useMemo(() => ({ getDomain }), [getDomain]);

  return tldjs;
};

export default useTld;
