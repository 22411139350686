'use client';

import { number, string } from 'prop-types';
import Image from 'next/image';

const BrandSchemeStatic = ({ alt, height = 26, scheme }) => {
  const src = `/images/brands/personly-heading-${scheme}.svg`;
  const width = Math.floor(height * 4);

  return <Image alt={alt} height={height} width={width} priority src={src} />;
};

BrandSchemeStatic.propTypes = {
  alt: string.isRequired,
  height: number,
  scheme: string.isRequired,
};

export default BrandSchemeStatic;
