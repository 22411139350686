'use client';

import { useCallback, useMemo } from 'react';

const useOptions = (
  handles,
  handle,
  toLabel,
  toValue = (handle) => handle,
  comparator = (option, value) => option.value === value
) => {
  const optionMaker = useCallback(
    (handle) => ({ label: toLabel(handle), value: toValue(handle) }),
    [toLabel, toValue]
  );

  const options = useMemo(
    () => handles.map(optionMaker),
    [handles, optionMaker]
  );

  const selectedOption = useMemo(
    () => options.find((option) => comparator(option, handle)),
    [options, handle, comparator]
  );

  return { options, selectedOption };
};

export default useOptions;
