'use client';

import { node, string } from 'prop-types';

import { NotificationsProvider } from '@personly/libs-providers';
import { Toaster } from '@personly/libs-widgets';
import { deploymentUrl } from '@personly/libs-urls';
import { toPathname } from '@personly/libs-pathnames';

const onNotify = (locale) => async (handle) => {
  const pathnames = ['translate', locale, handle];

  const url = deploymentUrl(toPathname(pathnames.join('/')));

  const response = await fetch(url);
  const { result } = await response.json();

  return result ? result.value : 'An error has occured';
};

const Provider = ({ children, locale }) => (
  <NotificationsProvider>
    <Toaster onNotify={onNotify(locale)} />
    {children}
  </NotificationsProvider>
);

Provider.propTypes = {
  children: node.isRequired,
  locale: string.isRequired,
};

export default Provider;
