const makeLocalizedPathname = (languages) => (pathname) => {
  const locales = languages.locales.join('|');
  const localeMatcher = new RegExp(`^/(${locales})`);

  if (languages.locale === languages.defaultLocale) {
    const hasLocale = localeMatcher.exec(pathname);
    if (!hasLocale) return pathname;

    const nonLocaleMatcher = new RegExp(`^/${hasLocale[1]}/(.*)`);
    const nonLocale = nonLocaleMatcher.exec(pathname);
    return !nonLocale ? `/` : `/${nonLocale[1]}`;
  }

  const hasLocale = localeMatcher.exec(pathname);
  if (hasLocale) {
    const nonLocaleMatcher = new RegExp(`^/${hasLocale[1]}/(.*)`);
    const nonLocale = nonLocaleMatcher.exec(pathname);
    return !nonLocale
      ? `/${languages.locale}`
      : `/${languages.locale}/${nonLocale[1]}`;
  }

  return `/${languages.locale}${pathname}`;
};

const toPathname = (slug) => `/${slug}`;

const stripTrailingSlashes = (url) => url.href.replace(/\/+$/, '');

export { makeLocalizedPathname, stripTrailingSlashes, toPathname };
