import { forwardRef } from 'react';
import { node } from 'prop-types';

const Form = forwardRef(({ children, ...props }, ref) => (
  <form ref={ref} {...props}>
    {children}
  </form>
));

Form.propTypes = {
  children: node.isRequired,
};

Form.displayName = 'Form';

export default Form;
