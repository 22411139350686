import { bool, node } from 'prop-types';

const CardFooter = ({ children, center }) => {
  const classNames = [
    'flex',
    'flex-row',
    'text-card-subtitle-color',
    'p-0.5',
    'text-sm',
    'rounded',
    'bg-background-color',
    'mr-2',
    'ml-2',
    'mb-2',
  ];

  if (center) classNames.push('justify-center');

  return <div className={classNames.join(' ')}>{children}</div>;
};

CardFooter.propTypes = { center: bool, children: node.isRequired };

export default CardFooter;
