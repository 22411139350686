'use client';

import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { node } from 'prop-types';

import { useNotifications, useSuspensions } from '@personly/libs-providers';
import { Form } from '@personly/libs-widgets';

import { useTickets as useTicketsService } from '../hooks';

const TicketsContext = createContext();

const TicketsProvider = ({ children }) => {
  const [email, setEmail] = useState('');
  const [body, setBody] = useState('');

  const { createNotification } = useNotifications();
  const { blocked } = useSuspensions();

  const { createTicket } = useTicketsService();

  const isDisabled = useMemo(
    () => !email || !body || blocked,
    [email, body, blocked]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      createTicket({ email, body }, (code) => {
        setBody('');
        setEmail('');

        createNotification('SUCCESS', code, {}, Infinity);
      });
    },
    [createNotification, body, createTicket, email]
  );

  const value = { isDisabled, email, setEmail, body, setBody };

  return (
    <TicketsContext.Provider value={value}>
      <Form onSubmit={handleSubmit}>{children}</Form>
    </TicketsContext.Provider>
  );
};

function useTickets() {
  const context = useContext(TicketsContext);
  if (context === undefined) throw new Error('useTickets requires a context!');

  return context;
}

TicketsProvider.propTypes = { children: node.isRequired };

export { TicketsProvider, useTickets };
