import { useCallback, useReducer } from 'react';

import { deploymentUrl } from '@personly/libs-urls';
import { useNotifications } from '@personly/libs-providers';

const initialState = null;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REFERRAL_CODE_FETCHED': {
      return action.data;
    }

    default:
      return state;
  }
};

const useReferralCodes = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { createNotification } = useNotifications();

  const getReferralCode = useCallback(
    async (id) => {
      const url = deploymentUrl('/agon/getReferralCode');

      url.searchParams.set('id', id);

      try {
        const response = await fetch(url);
        const { result } = await response.json();

        dispatch({ type: 'REFERRAL_CODE_FETCHED', data: result });
      } catch (err) {
        const code = err.message ? decodeURIComponent(err.message) : 'ERROR';
        createNotification('ERROR', code);
      }
    },
    [createNotification]
  );

  return { referralCode: state, getReferralCode };
};

export default useReferralCodes;
