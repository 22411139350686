'use client';

import { number, object } from 'prop-types';
import Image from 'next/image';
import { useMemo } from 'react';

import { CardFooter } from '@personly/libs-widgets';
import { useSchemes } from '@personly/libs-providers';

const PersonalFooter = ({ personal, width = 50 }) => {
  const { schemeHandle } = useSchemes();

  const { fileSources } = personal.organization;

  const image = useMemo(() => {
    const fileSource = fileSources[schemeHandle];
    if (!fileSource) return null;

    const { fileName, metadata } = fileSource;

    const src = `/images/organizations/${fileName}`;
    const ratio = parseInt(metadata.height, 10) / parseInt(metadata.width, 10);
    const height = Math.round(ratio * width);

    return { src, height, width, alt: fileName };
  }, [fileSources, schemeHandle, width]);

  return (
    <CardFooter>
      <div className="flex flex-row p-2">
        {image && (
          <div className="pr-2">
            <Image
              alt={image.alt}
              src={image.src}
              height={image.height}
              width={image.width}
            />
          </div>
        )}
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={personal.organization.url}
            className="font-bold"
          >
            {personal.organization.shortName}
          </a>
          &nbsp;&middot;&nbsp;{personal.organization.body}
        </div>
      </div>
    </CardFooter>
  );
};

PersonalFooter.propTypes = {
  personal: object.isRequired,
  width: number,
};

export default PersonalFooter;
