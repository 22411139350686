'use client';

import { array, node } from 'prop-types';
import { createContext, useContext } from 'react';

import { useValidators as useValidatorsService } from '@personly/libs-hooks';

const ValidatorsContext = createContext();

const ValidatorsProvider = ({ children, validators }) => {
  const value = useValidatorsService(validators);

  return (
    <ValidatorsContext.Provider value={value}>
      {children}
    </ValidatorsContext.Provider>
  );
};

function useValidators() {
  const context = useContext(ValidatorsContext);
  if (context === undefined)
    throw new Error('useValidators requires a context!');

  return context;
}

ValidatorsProvider.propTypes = {
  children: node.isRequired,
  validators: array.isRequired,
};

export { ValidatorsProvider, useValidators };
