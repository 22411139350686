'use client';

import { useMemo } from 'react';

import { makeLocalizedPathname } from '@personly/libs-pathnames';

const useLocalizedPathname = (languages) => {
  const result = useMemo(() => makeLocalizedPathname(languages), [languages]);

  return result;
};

export default useLocalizedPathname;
