'use client';

import { node } from 'prop-types';

import { StreamsProvider } from '@personly/libs-providers';

const url = process.env.NEXT_PUBLIC_STREAMS_URL;
const role = process.env.NEXT_PUBLIC_ROLE;

const Provider = ({ children }) => (
  <StreamsProvider role={role} url={url}>
    {children}
  </StreamsProvider>
);

Provider.propTypes = { children: node.isRequired };

export default Provider;
