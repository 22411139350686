'use client';

import { node, object } from 'prop-types';

import { useCookie, useTld } from '@personly/libs-hooks';
import { SchemesProvider } from '@personly/libs-providers';
import { deploymentUrl } from '@personly/libs-urls';

const { host } = deploymentUrl();

const Provider = ({ children, schemes }) => {
  const cookie = useCookie();
  const tld = useTld();

  return (
    <SchemesProvider cookie={cookie} host={host} schemes={schemes} tld={tld}>
      {children}
    </SchemesProvider>
  );
};

Provider.propTypes = {
  children: node.isRequired,
  schemes: object.isRequired,
};

export default Provider;
